<template>
  <!-- 数据统计pc -->
  <div id="dataStatistics">
    <img src="./img/bg.png" id="img" />
    <div id="loginBox">
      <div><img src="./img/login.png" />
      <div>Win生活</div></div>
        <div class="userBox">
          <van-tag round type="primary" style="    height: 20px;
    padding: 5px 17px;">15370938315</van-tag>
      
    </div>
    </div>
  
    <div class="box">
      <div class="cententBox">
        <div class="title">
          <img src="./img/title.png" />
        </div>
        <div class="sonBox">
          <div class="img">
            <img src="./img/1.png" style="width: 140px;"/>
            <div>驿马总额</div>
          </div>
          <div class="number">{{form.totalRiceGrainsSum||"0"}}<span class="unit">/个</span></div>
        </div>
        <div class="sonBox">
          <div class="img">
            <img src="./img/2.png"   style="width: 160px;"/>
            <div>官凭总额</div>
          </div>
          <div class="number">{{form.rewardPointsSum||"0"}}<span class="unit">/个</span></div>
        </div>
        <div class="sonBox">
          <div class="img">
            <img src="./img/3.png"  style="width: 180px;"/>
                        <div>金叶子总额</div>
          </div>
          <div class="number">{{form.goldRiceSum||"0"}}<span class="unit">/个</span></div>
        </div>
        <div class="sonBox">
          <div class="img">
            <img src="./img/4.png"  style="width: 200px;"/>
                        <div>VIP充值总额</div>
          </div>
          <div class="number">{{form.vipAmount||"0"}}<span class="unit">/元</span></div>
        </div>
        <div class="sonBox">
          <div class="img">
            <img src="./img/5.png"  style="width: 220px;"/>
                        <div>红尘坊订单总额</div>
          </div>
          <div class="number">{{form.welfareAmount||"0"}}<span class="unit">/元</span></div>
        </div>
        <div class="sonBox">
          <div class="img">
            <img src="./img/6.png"  style="width: 240px;"/>
                        <div>侠客集市订单总额</div>
          </div>
          <div class="number">{{form.cloudAmount||"0"}}<span class="unit">/元</span></div>
        </div>
      </div>
    </div>
      <van-overlay :show="loading" class="contaienr">
      <van-loading type="spinner" size="40" text-size="25" color="#fff">加载中</van-loading>
      </van-overlay>
    
  </div>
</template>
<script>
import { Toast } from "vant";
import {
  financialData
} from "@/api/dataStatistics";
export default {
  name:'dataStatistics',
  data() {
    return {
      loading: false,
      form:{},
    }
  },
    mounted() {
    this.getPage();
  },
    methods: {
    getPage() {
      this.loading=true;
      financialData().then((res) => {
        console.info(res)
        if(res.data.code==0){
this.form=res.data.data
        }
        this.loading=false;
      })
      .catch((err) => {
          Toast.fail("获取数据失败，请重新申请", err);
          this.loading = false;
        });
    }
    }
}
</script>
<style lang="less" scoped>
#dataStatistics {
  width: 100vw;
  height: 100vh;
  .contaienr {
  // background: red;
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
  #img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  #loginBox {
    display: flex;
    padding: 40px 0 0 50px;
    position: relative;
    justify-content: space-between;
    & >div{
       display: flex;
       img {
      width: 70px;
      height: 70px;
    }
    div {
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 70px;
      margin-left: 15px;
    }
    }
   
    .userBox{

padding-right: 30px;
  }
  }
  
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    .cententBox {
      width: 800px;
      // height: 834px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 20px;
      // border: 1px solid;
      border-image: linear-gradient(
          136deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
      backdrop-filter: blur(10px);
      padding: 0 34px 34px;
      .title {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        img {
          width: 400px;
          height: 55px;
        }
      }
      .sonBox {
        margin-bottom: 16px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #467aff;
        .img {
          height: 60px;
          img {
            height: 60px;
                position: absolute;

          }
          &>div{
            z-index: 99999;
            line-height: 60px;
    position: relative;
    font-size: 17px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #FFFFFF;
    padding-left: 28px;
          }
        }
        .number {
          font-size: 21px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #4175ff;
          line-height: 37px;
          padding-right: 35px;
          .unit {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            margin-left: 8px;
              font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
