var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"dataStatistics"}},[_c('img',{attrs:{"src":require("./img/bg.png"),"id":"img"}}),_c('div',{attrs:{"id":"loginBox"}},[_vm._m(0),_c('div',{staticClass:"userBox"},[_c('van-tag',{staticStyle:{"height":"20px","padding":"5px 17px"},attrs:{"round":"","type":"primary"}},[_vm._v("15370938315")])],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"cententBox"},[_vm._m(1),_c('div',{staticClass:"sonBox"},[_vm._m(2),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.totalRiceGrainsSum||"0")),_c('span',{staticClass:"unit"},[_vm._v("/个")])])]),_c('div',{staticClass:"sonBox"},[_vm._m(3),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.rewardPointsSum||"0")),_c('span',{staticClass:"unit"},[_vm._v("/个")])])]),_c('div',{staticClass:"sonBox"},[_vm._m(4),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.goldRiceSum||"0")),_c('span',{staticClass:"unit"},[_vm._v("/个")])])]),_c('div',{staticClass:"sonBox"},[_vm._m(5),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.vipAmount||"0")),_c('span',{staticClass:"unit"},[_vm._v("/元")])])]),_c('div',{staticClass:"sonBox"},[_vm._m(6),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.welfareAmount||"0")),_c('span',{staticClass:"unit"},[_vm._v("/元")])])]),_c('div',{staticClass:"sonBox"},[_vm._m(7),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.cloudAmount||"0")),_c('span',{staticClass:"unit"},[_vm._v("/元")])])])])]),_c('van-overlay',{staticClass:"contaienr",attrs:{"show":_vm.loading}},[_c('van-loading',{attrs:{"type":"spinner","size":"40","text-size":"25","color":"#fff"}},[_vm._v("加载中")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("./img/login.png")}}),_c('div',[_vm._v("Win生活")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("./img/title.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"140px"},attrs:{"src":require("./img/1.png")}}),_c('div',[_vm._v("驿马总额")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"160px"},attrs:{"src":require("./img/2.png")}}),_c('div',[_vm._v("官凭总额")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"180px"},attrs:{"src":require("./img/3.png")}}),_c('div',[_vm._v("金叶子总额")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":require("./img/4.png")}}),_c('div',[_vm._v("VIP充值总额")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"220px"},attrs:{"src":require("./img/5.png")}}),_c('div',[_vm._v("红尘坊订单总额")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"240px"},attrs:{"src":require("./img/6.png")}}),_c('div',[_vm._v("侠客集市订单总额")])])
}]

export { render, staticRenderFns }